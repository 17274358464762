  .layout{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 100vh;

    .content{
      flex: 1;
      width: 100%;

      .content_box{
        background: #FFFFFF;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
      }
    }
  }

  .page__title{
    position: absolute;
    top: 32px;
    left: 25px;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;

    color: $darkblue;
  }

  .ellipsis_two_line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .ellipsis_one_line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .no_wrap{
    white-space: nowrap;
  }

  .admin_title{
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #2499E3;
  }
  .box_white{
    background: #FFFFFF;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
  .border_bottom{
    border-bottom: 1px solid #EBEFF2;
  }
  .border_top{
    border-top: 1px solid #EBEFF2;
  }

  .ant-btn-sm{
    height: 24px;
    padding: 0 2px;
    font-size: 12px;
    line-height: 22px;
  }
  .ant-btn-circle.ant-btn-sm{
    min-width: 24px;
  }
  .visually-hidden {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    clip: rect(0 0 0 0);
    overflow: hidden;
  }
