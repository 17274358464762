
body{
  color: $dark;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif !important;
}

.container{
  max-width: 1200px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.container::after,
.clear::after{
  content: '';
  display: table;
  clear: both;
}

.white{ color: #fff; }
.black{ color: #192A3E; }
.light{ color: #ccc; }
.lighter{ color: #C2CFE0; }
.lighty{ color: #90A0B7; }
.darkblue{ color: $darkblue; }
.blue{ color: #109CF1;}
.red{ color: crimson; }

.bg-white{ background: #fff; }

.link{ color: inherit; display: inline; }
.link:hover{ color: $color; }

.left{ float: left; }
.right{ float: right; }

.text-center{ text-align: center; }
.text-right{ text-align: right; }
.text-justify{ text-align: justify; }

.thin{ font-weight: 300; }
.normal{ font-weight: 400; }
.semibold{ font-weight: 500; }
.bold{ font-weight: 700; }
.extrabold{ font-weight: 900; }

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height{
  min-height: 100vh;
}
.height100{ height: 100%; }
.height200{ height: 200px; }
.height400{ height: 400px; }

.w100{ width: 100%; }

// .ant-row{ margin-bottom: 0 !important; }

.relative{
  position: relative;
  width: 100%;
}

.rotate180{transform: rotate(180deg);}

.upper{ text-transform: uppercase; }

.pointer{ cursor: pointer; }

h1, h2, h3, h4, h5, h6{ color: inherit; }

a, a:hover{ color: var(--color); }
a:focus{ text-decoration: underline; }

hr{
  border: none;
  height: 1px;
  width: 100%;
  display: block;
  margin: 10px 0;
  background: #D3D3D3;
}

.border{ border: 1px solid #ccc; }

.paragraph{
  font-size: 32px;
  line-height: 1.2;
}

.br{ border-radius: 20px; }
.brr{ border-radius: 8px; }

.lh15{ line-height: 1.5; }
.lh12{ line-height: 1.2; }
.ls1{ letter-spacing: 1px; }
.ls2{ letter-spacing: 2px; }

.maxw480{
  max-width:480px;
  margin-left: auto;
  margin-right: auto;
}
.maxw600{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.maxw720{
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.maxw900{
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.myBtn{
  display: inline-block;
  outline: none;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
  letter-spacing: 1px;
  transition: 0.2s;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &.sm{
    height: 34px;
    line-height: 34px;
    padding: 0 20px;
    font-size: 14px;

    &.outline{
      line-height: 34px !important;
      font-weight: 500;
    }
  }

  &:disabled{
    opacity: 0.5;
    &:hover{
      transform: none;
      box-shadow: none !important;
      cursor: initial;
    }
  }

  + .myBtn{ margin-left: 10px; }

  &.block{
    display: block;
    width: 100%;
    text-align: center;
  }

  &.br{
    border-radius: 30px;
  }

  &.brr{
    border-radius: 8px;
  }

  &.default{
    background: var(--color);
    color: var(--white);

    &:hover, &:focus{
      box-shadow: 0 12px 6px -8px var(--color05);
    }

    &.outline{
      background: transparent;
      color: var(--color);
      border: 1px solid var(--color);
      line-height: 38px;

      &:hover, &:focus{
        background: var(--color01);
        // box-shadow: none;
      }
    }
  }

  &:hover, &:focus{
    transform: translateY(-1px);
  }

  &.serious:hover, &:focus{ transform: none !important; }

  &.green{
    background: var(--green);
    color: var(--white);

    &:hover, &:focus{
      box-shadow: 0 12px 6px -8px var(--green05);
      transform: translateY(-2px);
    }
  }

  &.red{
    background: var(--red);
    color: var(--white);

    &:hover, &:focus{
      box-shadow: 0 12px 6px -8px var(--red05);;
    }

    &.outline{
      background: transparent;
      color: var(--red);
      border: 1px solid var(--red);
      line-height: 38px;

      &:hover, &:focus{
        background: var(--red);
        color: var(--white);
        transform: none;
      }
    }
  }
}


$sizes: 9, 10, 12, 13, 14, 16, 18, 20, 22, 24, 28, 32, 36, 40, 44, 48, 60, 90;
@each $size in $sizes {
  .size#{$size} {
    font-size: #{$size}px;
  }
}


$opacities: 01, 02, 03, 04, 05, 06, 07, 08, 09, 1;
@each $opacity in $opacities {
  .opacity#{$opacity} {
    opacity: #{$opacity / 10};
  }
}

$ps: 5, 10, 12, 15, 20, 25, 30, 40, 50, 60, 90;
@each $p in $ps {
  .p#{$p} {
    padding: #{$p}px;
  }
}

@each $py in $ps {
  .py#{$py} {
    padding-top: #{$py}px;
    padding-bottom: #{$py}px;
  }
}

@each $px in $ps {
  .px#{$px} {
    padding-left: #{$px}px;
    padding-right: #{$px}px;
  }
}

@each $pl in $ps {
  .pl#{$pl} {
    padding-left: #{$pl}px;
  }
}

@each $pr in $ps {
  .pr#{$pr} {
    padding-right: #{$pr}px;
  }
}

@each $pt in $ps {
  .pt#{$pt} {
    padding-top: #{$pt}px;
  }
}

@each $pb in $ps {
  .pb#{$pb} {
    padding-bottom: #{$pb}px;
  }
}

.m0{
  margin: 0 !important;
}

$ms: 5, 10, 12, 15, 20, 25, 30, 40, 50, 60, 90;
@each $m in $ms {
  .ml#{$m} {
    margin: #{$m}px;
  }
}

@each $ml in $ms {
  .ml#{$ml} {
    margin-left: #{$ml}px;
  }
}

@each $mr in $ms {
  .mr#{$mr} {
    margin-right: #{$mr}px;
  }
}

@each $mt in $ms {
  .mt#{$mt} {
    margin-top: #{$mt}px;
  }
}

@each $mb in $ms {
  .mb#{$mb} {
    margin-bottom: #{$mb}px;
  }
}

@each $my in $ms {
  .my#{$my} {
    margin-top: #{$my}px;
    margin-bottom: #{$my}px;
  }
}

@each $mx in $ms {
  .mx#{$mx} {
    margin-left: #{$mx}px;
    margin-right: #{$mx}px;
  }
}
